export const listFontsize = [
  'xs',
  'sm',
  'base',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
]

export const defaultFontSizeIndex = 2 // base
