import { defaultFontSizeIndex } from '../utils/fontsizeList'
import { generateQueryOfEndPoint, roundDecimals } from '../utils/functions'
import { VIEW_RANGE_TYPES } from '../utils/planSets'
import { IAccount } from './accounts'
import { getAssetByIds, IAsset } from './assets'
import client, {
  IFilter,
  PAGE_SIZE,
  PAGE_SIZE_RECENT,
  ResponseMeta,
} from './client'
import { IContract } from './contracts'
import { IFile } from './files'
import { getProductByIds, IProduct } from './products'
import { IProject } from './projects'
import { TResourcePayload } from './resource'
import { IService } from './services'
import { ITag } from './tags'
import { getUsers, IUser } from './users'

export interface IPlanSet {
  id: number
  name: string
  account: IAccount | null
  account_id: number | null
  maintenanceContracts?: IContract[] | null
  projects?: IProject[] | null
  serviceTickets?: IService[] | null
  street_address?: string | null
  street_address_2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
  country?: string | null
  created_at: Date | string
  files?: IFile[] | null
  inbound_email_address?: string | null
  tags?: ITag[] | null
}

export interface IPlanSetResponse {
  data: IPlanSet[]
  meta: ResponseMeta
}

export interface IPlanSetPayload
  extends Omit<
    IPlanSet,
    'id' | 'created_at' | 'tags' | 'account' | 'account_id'
  > {
  resources?: TResourcePayload[] | null
  tags?: number[] | null
  account_id?: number | null
}

export interface IPlanSetFilters extends IFilter {
  accountId?: number
  contractId?: number
  projectId?: number
  serviceId?: number
  include?: string
}

export const getPlanSets = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  accountId = undefined,
  contractId = undefined,
  projectId = undefined,
  serviceId = undefined,
  include = undefined,
}: IPlanSetFilters) => {
  return client.get<IPlanSetResponse>(
    generateQueryOfEndPoint('/api/planSets', {
      sort: `${sortDirection === 'desc' ? '-' : ''}${sortBy}`,
      'filter[search]': search,
      'filter[account_id]': accountId,
      'filter[maintenance_contract_id]': contractId,
      'filter[project_id]': projectId,
      'filter[service_ticket_id]': serviceId,
      limit: pageSize,
      page: currentPage,
    }) + `&include=${include || 'files,inboundEmailAddress,account'}`,
  )
}

export const getRecentPlanSets = async (props: IPlanSetFilters) => {
  return getPlanSets({
    currentPage: 1,
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
  })
}

export const createPlanSet = async (payload: IPlanSetPayload) => {
  return client.post('/api/planSets', payload)
}

export const editPlanSet = (planSetId: number, payload: IPlanSetPayload) => {
  return client.put<{ data: IPlanSet }>(`/api/planSets/${planSetId}`, payload)
}

export const deletePlanSet = async (planSetId: number) => {
  return client.delete(`/api/planSets/${planSetId}`)
}

export const detailPlanSet = async (planSetId: number) => {
  return client.get<{ data: IPlanSet }>(
    `api/planSets/${planSetId}?include=maintenanceContracts,projects,serviceTickets,inboundEmailAddress,files,tags,account`,
  )
}

// -------------------------------------------------- PLAN SETS - FLOOR PLAN -------------------------------------------------- //

export interface IFloorPlan extends Omit<IFile, 'custom_properties'> {
  custom_properties: TCustomProps[]
}
export const initLegend: CustomePropLegend = {
  x: undefined,
  y: undefined,
  generateFromFloorPlans: [],
  visible: false,
  width: 0,
  height: 0,
  fontsizeIndex: defaultFontSizeIndex,
}

export type CustomePropLegend = {
  x?: number
  y?: number
  width: number
  height: number
  visible: boolean
  generateFromFloorPlans: number[]
  fontsizeIndex: number
}
export type TCustomProps = {
  name: string
  opacity: number
  bgWidth: number
  bgHeight: number
  description?: string
  squareFootage?: number
  setScale: {
    x: number
    y: number
    points: [number, number, number, number]
    distance: number
  }
  legend: CustomePropLegend
  areas: Record<string, Area>
  rootLevelNodes: string[]
  linearNodes: { key: string; depth: number }[]
  nodes: Record<string, TFloorPlanNode>
  comments: ICommentNode[]
  imageWidgetVisible: boolean
  commentVisible: boolean
  viewCoverageVisible: boolean
  deviceLabelVisible: boolean
}

export type Area = {
  id: string
  x: number
  y: number
  scaleX: number
  scaleY: number
  rotate: number
  points: { x: number; y: number }[]
}

export type TFloorPlanNode =
  | IImageNode
  | IAssetNode
  | IProductNode
  | IIMDFNode
  | ITextNode
  | IGroupNode

export interface IBaseNode {
  refId: number
  children?: string[]
  parentGroupId?: string
  id: string
  name: string
  x: number
  y: number
  width: number
  height: number
  opacity: number
  hidden: boolean
  rotate: number
  scaleX: number
  scaleY: number
  keepRatio: boolean
  note: string
  type: 'image' | 'asset' | 'product' | 'IDF' | 'MDF' | 'text' | 'group'
}

export type CameraViewCoverage = {
  bgId: number
  rotate: number
  opacity: number
  scaleX: number
  scaleY: number
  type: (typeof VIEW_RANGE_TYPES)[number]
}

export interface IAssetNode extends IBaseNode {
  icon: string
  bgId: number
  deviceTagId: string
  viewCoverages: CameraViewCoverage[]
  parentImdfId?: string
  simulatedViewId?: number
  location?: string
  type: 'asset'
  type_id: number
}

export interface IProductNode extends IBaseNode {
  icon: string
  bgId: number
  deviceTagId: string
  viewCoverages: CameraViewCoverage[]
  parentImdfId?: string
  simulatedViewId?: number
  location?: string
  type: 'product'
}

export interface IImageNode extends IBaseNode {
  type: 'image'
}

export interface IIMDFNode extends IBaseNode {
  deviceTagId: string
  type: 'IDF' | 'MDF'
}

export interface ITextNode extends IBaseNode {
  fontFamily: string
  fontSize: number
  fontStyle: string
  textAlign: string
  textColor: string
  textColorOpacity: number
  bgColor: string
  bgColorOpacity: number
  strokeColor: string
  strokeColorOpacity: number
  type: 'text'
}

export interface IGroupNode extends IBaseNode {
  expanded: boolean
  type: 'group'
}

export interface ICommentNode {
  id: string
  x: number
  y: number
  content: string
  userId: number
  createdAt: Date
  updatedAt?: Date
  type: 'comment'
}

export const getPlanSetDetail = async (id: number) => {
  const res = await detailPlanSet(id)
  const [floorPlans, images] = (res.data.data.files || []).reduce(
    (acc, file) => {
      //@ts-ignore
      acc[file.collection_name === 'default' ? 0 : 1].push(file)
      return acc
    },
    [[] as IFloorPlan[], [] as IFile[]],
  )
  return {
    planSet: res.data.data,
    floorPlans,
    images,
  }
}

export const getAllUsers = async () => {
  let users: IUser[] = []
  const recur = async (page = 1) => {
    const { data } = await getUsers({ pageSize: 30, currentPage: page })
    users = [...users, ...data.data]
    const { current_page, last_page } = data.meta
    if (current_page < last_page) {
      await recur(current_page + 1)
    }
  }
  await recur()
  return users
}

export const getAssets = async (floorPlans: IFloorPlan[]) => {
  const allAssetId = new Set<number>()
  floorPlans.forEach(floorPlan => {
    const nodes = floorPlan.custom_properties[0].nodes
    Object.keys(nodes).forEach(key => {
      if (nodes[key].type === 'asset') {
        allAssetId.add(nodes[key].refId)
      }
    })
  })
  if (!!allAssetId.size) {
    const response = await getAssetByIds(
      Array.from(allAssetId),
      'include=accounts',
    )
    return response.data.data as IAsset[]
  }
  return []
}

export const getProducts = async (floorPlans: IFloorPlan[]) => {
  const allProductId = new Set<number>()
  floorPlans.forEach(floorPlan => {
    const nodes = floorPlan.custom_properties[0].nodes
    Object.keys(nodes).forEach(key => {
      if (nodes[key].type === 'product') {
        allProductId.add(nodes[key].refId)
      }
    })
  })
  if (!!allProductId.size) {
    const response = await getProductByIds(Array.from(allProductId))
    return response.data.data as IProduct[]
  }
  return []
}

export const createFloorPlan = async ({
  resource_id,
  file,
  custom_properties,
}: {
  resource_id: number
  file: File
  custom_properties: Pick<
    TCustomProps,
    'bgWidth' | 'bgHeight' | 'name' | 'description' | 'squareFootage'
  >
}) => {
  const formData = new FormData()
  const customProps: TCustomProps = {
    ...custom_properties,
    opacity: 100,
    // @ts-ignore
    nodes: { __KEEP__: { __KEEP__: true } },
    linearNodes: [],
    rootLevelNodes: [],
    comments: [],
    imageWidgetVisible: false,
    commentVisible: true,
    viewCoverageVisible: true,
    deviceLabelVisible: true,
    setScale: {
      x: 0,
      y: 0,
      distance: 0,
      points: [
        roundDecimals(custom_properties.bgWidth / 2 - 180),
        roundDecimals(custom_properties.bgHeight / 2),
        roundDecimals(custom_properties.bgWidth / 2 + 180),
        roundDecimals(custom_properties.bgHeight / 2),
      ],
    },
    legend: initLegend,
    areas: {
      // @ts-ignore
      __KEEP__: { __KEEP__: true },
    },
  }

  formData.append('resource_id', resource_id + '')
  formData.append('resource_type', 'plan_set')
  formData.append('file', file)
  formData.append('custom_properties', JSON.stringify([customProps]))

  return client.post<{ data: IFloorPlan }>('/api/files', formData)
}

export const updateFloorPlan = (
  id: number,
  payload: RecursivePartial<IFloorPlan>,
) => {
  return client.put<{ data: IFloorPlan }>(`/api/files/${id}`, payload)
}

export const updateCustomProps = (
  floorPlan: IFloorPlan,
  payload: Partial<TCustomProps>,
) => {
  return client.put<{ data: IFloorPlan }>(`/api/files/${floorPlan.id}`, {
    custom_properties: [{ ...floorPlan.custom_properties[0], ...payload }],
  })
}

export const replaceFloorPlan = async (
  oldData: IFloorPlan,
  newData: {
    file: File
    custom_properties: Partial<TCustomProps>
  },
) => {
  await deleteFloorPlan(oldData.id)
  return createFloorPlan({
    resource_id: oldData.resource_id,
    file: newData.file,
    custom_properties: {
      ...oldData.custom_properties[0],
      ...newData.custom_properties,
    },
  })
}

export const deleteFloorPlan = (id: number) => {
  return client.delete(`/api/files/${id}`)
}
