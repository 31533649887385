import axios from 'axios'

import { OptionValue } from '../utils/form'
import { generateQueryOfEndPoint } from '../utils/functions'
import { IAccount } from './accounts'
import client, {
  BASE_URL,
  IFilter,
  PAGE_SIZE,
  PAGE_SIZE_RECENT,
  ResponseMeta,
} from './client'
import { IContact } from './contacts'
import { IFile } from './files'
import { IInvoice } from './invoices'
import { INote } from './notes'
import { IOpportunity } from './opportunities'
import { IProduct } from './products'
import { IProject } from './projects'
import { IQuoteSettingPayload } from './settings'
import { ITag } from './tags'
import { ITaxVendor } from './taxVendors'
import { IUser } from './users'

export interface ResponseQuoteMeta extends ResponseMeta {
  expired_count: number
  recently_updated_count: number
  warning_count: number
  follow_up_lated_count: number
  needs_follow_up_count: number
  status_count: Record<string, number>
}
export interface IResponseQuotes {
  data: IQuote[]
  meta: ResponseQuoteMeta
}
export interface IFormLineItem {
  id: number
  quantity: number | null
  unit_price: number | null
  type: OptionValue | null
  product: OptionValue | null
  sku?: string | null
  is_tax_exempted?: boolean
  is_hidden?: 1 | 0
  is_optional?: boolean
  sub_description?: string | null
}

export interface ILineItem {
  id?: number | null
  description: string
  quantity: number | null
  unit_price: number | null
  type: string
  type_id: number | null
  product?: IProduct | null
  product_id?: number | null
  amount?: number | null
  sku?: string | null
  is_tax_exempted?: boolean
  is_hidden?: 1 | 0
  is_optional?: boolean
  sub_description?: string | null
}

export interface IQuote {
  id: number
  account_id: number
  account: IAccount
  project_id?: number | null
  project?: IProject | null
  opportunity_id?: number | null
  opportunity?: IOpportunity | null
  invoices?: IInvoice[] | null
  regarding?: string | null
  quote_number: number
  visible_to_customer?: boolean
  attention?: string | null
  scope?: string | null
  purchase_order_number?: string | null
  is_purchase_order_received?: boolean | null
  site_address?: string | null
  status_id?: number | null
  status?: string | null
  warranty_terms?: IQuoteSettingPayload | null
  conditions?: IQuoteSettingPayload[] | null
  maintenance?: string | null
  tax_rate?: number | null
  tax_vendor_ids?: number[] | null
  valid_till?: Date | string | null
  updated_at?: Date | string
  created_at: Date | string
  status_date?: Date | string
  quoteLineItems?: ILineItem[] | null
  allFiles?: IFile[] | null
  allNotes?: INote[] | null
  notes?: INote[] | null
  is_watched?: boolean
  watchers?: IUser[] | null
  watchers_count?: number | null
  taxVendors?: ITaxVendor[] | null
  pdf_url?: string | null
  contact?: IContact | null
  contacts?: IContact[] | null
  discount?: number | null
  pdf_notes?: string | null
  public_url?: string | null
  inbound_email_address?: string | null
  sub_total?: number | null
  tax_amount?: number | null
  total_amount?: number | null
  is_private?: boolean
  tags?: ITag[] | null
  sales_pipeline_id?: number | null
  follow_up_date?: Date | string | null
  follow_up_complete?: boolean
  is_warning?: boolean
  is_expired?: boolean
}

export interface IQuotePayload
  extends Omit<
    IQuote,
    | 'id'
    | 'account'
    | 'watchers'
    | 'pdf_url'
    | 'account_id'
    | 'quote_number'
    | 'contact'
    | 'contacts'
    | 'created_at'
    | 'tags'
    | 'project'
    | 'project_id'
    | 'invoices'
  > {
  watchers?: number[] | null
  account_id?: number | null
  quote_number?: number | null
  contact?: number | null
  contacts?: number[] | null
  tags?: number[] | null
  invoice_ids?: number[] | null
}

export interface IPreviewQuotePayload {
  quoteId: number
  email?: string | null
  expires: number
  signature: string
}
export interface ISendQuotePayload {
  contacts?: number[] | null
  users?: number[] | null
  emails?: string[] | null
}

export interface IFilterQuotes extends IFilter {
  filterAccount?: OptionValue | null
  filterOpportunity?: OptionValue | null
  filterProject?: OptionValue | null
  filterArchived?: string | null
  filterStatus?: OptionValue | null
  filterRecentUpdated?: boolean
  filterWarning?: boolean
  filterExpired?: boolean
  filterFollowingUp?: boolean
  fillterFollowUpLated?: boolean
  filterKpi?: boolean
  filterStatusDirection?: 'include' | 'exclude' | null
  filterSpecialStatus?: string | null
  filterSalePipeline?: number | null
}

export interface IPayloadQuotes extends IFilterQuotes {
  include?: string
}

export const getQuotes = async ({
  search = '',
  sortBy = 'id',
  sortDirection = 'desc',
  pageSize = PAGE_SIZE,
  currentPage = 1,
  filterAccount = null,
  filterOpportunity = null,
  filterProject = null,
  filterArchived = null,
  filterStatus = null,
  filterRecentUpdated = false,
  filterWarning = false,
  filterExpired = false,
  filterFollowingUp = false,
  fillterFollowUpLated = false,
  filterKpi = false,
  filterStatusDirection = 'include',
  filterSpecialStatus = null,
  filterSalePipeline = null,
  include = 'quoteLineItems.product,account,opportunity,inboundEmailAddress,invoices,project',
}: IPayloadQuotes) => {
  const sort = `${sortDirection === 'desc' ? '-' : ''}${sortBy}`
  const endPoint = generateQueryOfEndPoint('/api/quotes', {
    sort,
    'filter[search]': search,
    limit: pageSize,
    page: currentPage,
    'filter[recently_updated]': filterRecentUpdated ? 1 : null,
    'filter[warning]': filterWarning ? 1 : null,
    'filter[expired]': filterExpired ? 1 : null,
    'filter[follow_up_lated]': fillterFollowUpLated ? 1 : null,
    'filter[needs_follow_up]': filterFollowingUp ? 1 : null,
    'filter[account_id]': filterAccount?.value || null,
    'filter[project_id]': filterProject?.value || null,
    'filter[opportunity_id]': filterOpportunity?.value || null,
    'filter[sales_pipeline_id]': filterSalePipeline || null,
    [`filter[${
      filterStatusDirection === 'exclude' && filterSpecialStatus ? '-' : ''
    }status]`]: filterSpecialStatus || filterStatus?.label || null,
    'filter[archived]': filterArchived,
    kpi: filterKpi ? 1 : 0,
    include,
  })
  return client.get<IResponseQuotes>(endPoint.slice(0, -1))
}

export const getRecentQuotes = async (props: IPayloadQuotes) => {
  return getQuotes({
    currentPage: 1,
    ...props,
    sortBy: 'recent',
    sortDirection: 'asc',
    pageSize: PAGE_SIZE_RECENT,
  })
}

export const createQuote = async (payload: IQuotePayload) => {
  return client.post('/api/quotes', payload)
}

export const editQuote = (quoteId: number, payload: IQuotePayload) => {
  return client.put<{ data: IQuote }>(`/api/quotes/${quoteId}`, payload)
}
export const deleteQuote = async (quoteId: number) => {
  return client.delete(`/api/quotes/${quoteId}`)
}

export const detailQuote = async (quoteId: number) => {
  return client.get<{ data: IQuote }>(
    `api/quotes/${quoteId}?include=quoteLineItems.product,quoteLineItems.product.files,account,opportunity,notes,allFiles,watchers,contact,contacts,project,invoices,taxVendors,inboundEmailAddress,tags`,
  )
}

export const previewQuote = async ({
  quoteId,
  email,
  signature,
  expires,
}: IPreviewQuotePayload) => {
  try {
    return await axios.get(
      `${BASE_URL}/api/preview/quotes/${quoteId}?${
        email ? `email=${email}&` : ''
      }expires=${expires}&signature=${signature}`,
    )
  } catch (error) {
    return error
  }
}

export const quoteToPdf = async (pdf_url: string) => {
  return axios.get(pdf_url, {
    responseType: 'arraybuffer',
  })
}

export const quoteToProject = async (quoteId: number, name: string) => {
  return client.post(`api/quotes/${quoteId}/projects`, { name })
}

export const quoteToInvoice = async (quoteId: number) => {
  return client.post(`api/quotes/${quoteId}/invoices`)
}

export const createDraftQuote = async (quoteId: number) => {
  return client.post(`api/quotes/${quoteId}/drafts`)
}

export const sendQuote = async (
  quoteId: number,
  payload: ISendQuotePayload,
) => {
  return client.post(`/api/quotes/${quoteId}/send`, payload)
}

export const getQuoteNumber = async () => {
  return client.post<{ data: { quote_number: string } }>(
    '/api/quotes/quoteNumbers',
  )
}
