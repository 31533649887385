import { orderBy } from 'lodash'
import { useState } from 'react'
import shallow from 'zustand/shallow'

import { Select, SelectProps, Tooltip } from '../../../components'
import useStore, { Store } from '../../../store'
import { getListParentSettings } from '../../../utils/settings'

interface Props extends Omit<SelectProps, 'options'> {
  defaultValue?: number | null
  onSelect?: (value: number | null) => void
  className?: string
  selectType?: string
}

const mapState = (state: Store) => ({
  productCategories: state.settings?.product?.product_types,
  assetSubtypes: state.settings?.asset?.asset_sub_types,
})

export const AssetParentSubTypesSelect = ({
  defaultValue = null,
  onSelect,
  selectType = 'asset',
  ...props
}: Props) => {
  const [selected, setSelected] = useState(defaultValue)
  const { productCategories, assetSubtypes } = useStore(mapState, shallow)
  const listDefault =
    selectType === 'product' ? productCategories : assetSubtypes
  const listParent = getListParentSettings(
    orderBy(listDefault, ['label', 'asc']),
    [],
  )
  if (!listDefault) {
    return null
  }

  const handleSelect = (value: number | null) => {
    setSelected(value)
    onSelect?.(value)
  }

  const renderPath = (level = 1, step = 1): string => {
    if (level === 1) {
      return ''
    }
    return step < level ? '.../ ' + renderPath(level, step + 1) : ''
  }

  return (
    <Select
      className='w-full capitalize cursor-pointer'
      placeholder='All Types'
      animation={undefined}
      onSelect={handleSelect}
      onClear={() => handleSelect(null)}
      value={selected}
      optionLabelProp='displayLabel'
      {...props}
    >
      {listParent.map(item => (
        <Select.Option
          key={item.id}
          value={item.id}
          className='capitalize p-0'
          displayLabel={
            <div className='flex items-center gap-2'>
              <span className={`w-4 font-icon-${item.meta?.media?.iconName}`} />
              <span>{item.label}</span>
            </div>
          }
        >
          <Tooltip
            content={item.fullPathStr}
            popupClassName='!z-[9999]'
            placement='left'
            className='w-full p-2'
            delay={300}
          >
            <div className='flex items-center gap-2'>
              <span className={`w-4 font-icon-${item.meta?.media?.iconName}`} />
              <div className='text-black-800' title={item.fullPathStr || ''}>
                {renderPath(item?.level || 1)}
                {item.label}
              </div>
            </div>
          </Tooltip>
        </Select.Option>
      ))}
    </Select>
  )
}
