import { differenceInCalendarDays } from 'date-fns'

import { initFilter } from '../services/client'
import { IContact } from '../services/contacts'
import { IDetailSettings } from '../services/settings'
import {
  IFilterQuotes,
  IFormLineItem,
  ILineItem,
  IQuote,
} from './../services/quotes'
import { ITaxVendor } from './../services/taxVendors'
import { OptionValue, OptionValueTax } from './form'
import { compareFilterPagination, currencyFormat } from './functions'
import { TLeftSideMenu } from './menus'

export const QUOTE_STATUS_ACCEPTED = 'Accepted'
export const QUOTE_STATUS_CHANGE_REQUESTED = 'Changes Requested'
export const QUOTE_STATUS_CLOSED = 'Closed'
export const QUOTE_STATUS_CREATED = 'Created'
export const QUOTE_STATUS_DISCARDED = 'Discarded'
export const QUOTE_STATUS_INVOICED = 'Invoiced'
export const QUOTE_STATUS_PAID = 'Paid'
export const QUOTE_STATUS_PARTIAL_PAID = 'Partially Paid'
export const QUOTE_STATUS_SENT = 'Sent'
export const QUOTE_STATUS_EXPIRED = 'Expired'
export const QUOTE_STATUS_WARNING = 'Warning'

export const QUOTE_COLLECTION_CUT_SHEETS = 'quote_cut_sheets'

export const initSpecialFilterQuote: IFilterQuotes = {
  filterStatus: null,
  filterSpecialStatus: null,
  filterExpired: false,
  filterWarning: false,
  filterRecentUpdated: false,
  filterFollowingUp: false,
  fillterFollowUpLated: false,
}

export const initFilterQuote: IFilterQuotes = {
  ...initFilter,
  search: '',
  filterAccount: null,
  filterOpportunity: null,
  filterArchived: null,
  ...initSpecialFilterQuote,
}
export const initFormLineItem: IFormLineItem = {
  id: 1,
  product: null,
  quantity: null,
  unit_price: null,
  type: null,
  sku: null,
  is_tax_exempted: false,
  is_optional: false,
}
export const isHasFilterQuote = (filter: IFilterQuotes) =>
  !!filter?.filterAccount ||
  !!filter?.filterOpportunity ||
  !!filter?.filterProject ||
  !!filter?.filterStatus ||
  !!filter?.filterSpecialStatus ||
  !!filter?.filterExpired ||
  !!filter?.filterWarning ||
  !!filter?.filterRecentUpdated ||
  !!filter?.filterFollowingUp ||
  !!filter?.fillterFollowUpLated ||
  compareFilterPagination(filter, initFilterQuote)

export const quoteSideMenu: TLeftSideMenu[] = [
  { icon: 'money', name: 'Line Items', id: 'line-items' },
  { icon: 'money', name: 'Optional Line Items', id: 'optional-line-items' },
  { icon: 'link', name: 'Relating Resources', id: 'relating' },
  { icon: 'info', name: 'Quote detail', id: 'detail' },
  { icon: 'attachment', name: 'Purchase Order', id: 'po' },
  { icon: 'user', name: 'Attention', id: 'attention' },
  { icon: 'location', name: 'Site Address', id: 'address' },
]

export const hasOptionLineItems = (lines: ILineItem[] | null) => {
  if (!lines || lines.length === 0) {
    return false
  }
  return lines.some(item => Boolean(item.is_optional))
}

export const hasDefaultLineItems = (lines: ILineItem[] | null) => {
  if (!lines || lines.length === 0) {
    return false
  }
  return lines.some(item => !Boolean(item.is_optional))
}

export const totalPriceLineItems = (
  lines: ILineItem[] | null,
  format = true,
) => {
  if (!lines || lines.length === 0) {
    return 0
  }
  const result = lines.reduce((total, item) => total + +(item.amount || 0), 0)
  return format ? currencyFormat(result) : result
}

export const totalPriceOptionLineItems = (lines: ILineItem[] | null) => {
  if (!lines || lines.length === 0) {
    return 0
  }
  const result = lines.reduce((total, item) => {
    const amount = item.is_optional && item.amount ? item?.amount : 0
    return total + amount
  }, 0)
  return result
}

export const getListDefaultLineItems = <T extends { is_optional?: boolean }>(
  lineItems: T[] | null,
) => {
  if (!lineItems || lineItems.length === 0) {
    return []
  }
  return lineItems.filter(item => !Boolean(item.is_optional))
}
export const getListOptionLineItems = <T extends { is_optional?: boolean }>(
  lineItems: T[] | null,
) => {
  if (!lineItems || lineItems.length === 0) {
    return []
  }
  return lineItems.filter(item => Boolean(item.is_optional))
}

export const isWarningQuote = (quote: IQuote) => Boolean(quote.is_warning)

export const isRejectedQuote = (quote: IQuote) =>
  quote.status === QUOTE_STATUS_DISCARDED

export const isExpiredQuote = (quote: IQuote) => Boolean(quote.is_expired)

export const isFollowingUpQuote = (quote: IQuote) => {
  if (!quote.follow_up_date) {
    return false
  }
  const days = differenceInCalendarDays(
    new Date(quote.follow_up_date),
    new Date(),
  )
  return days >= 0
}
export const isFollowUpLateQuote = (quote: IQuote) => {
  if (!quote.follow_up_date) {
    return false
  }
  const days = differenceInCalendarDays(
    new Date(quote.follow_up_date),
    new Date(),
  )
  return days < 0
}

export const isNewUpdateQuote = (quote: IQuote) => {
  const updateDate = quote?.updated_at
  if (!updateDate) {
    return true
  }
  const diffNewUpdateDate = differenceInCalendarDays(
    new Date(),
    new Date(updateDate),
  )
  return diffNewUpdateDate <= 1
}

export const validProductLineItems = (
  productLineItems: IFormLineItem[] | null,
) => {
  if (!productLineItems || productLineItems.length === 0) {
    return true
  }
  const listProducts = productLineItems.filter(
    item =>
      (!!item.product && !!item.product.label) ||
      !!item.unit_price ||
      (item.quantity && item.quantity > 0) ||
      !!item.type ||
      !!item.sku,
  )
  if (listProducts.length === 0) {
    return true
  }
  return listProducts.some(
    item =>
      item.unit_price &&
      item.quantity &&
      item.quantity !== 0 &&
      !!item.type &&
      !!item.product,
  )
}

export const validTaxVendors = (opt: OptionValue[], isTaxExempted = true) => {
  if (isTaxExempted) {
    return true
  }
  return opt.length > 0
}
export const filterValidProductLineItems = (
  producLineItems: IFormLineItem[],
) => {
  return producLineItems.filter(
    item =>
      !!item.product &&
      item.unit_price &&
      item.quantity &&
      !!item.type &&
      item.quantity !== 0,
  )
}

export const generateFormLineItemToSubmit = (
  producLineItems: IFormLineItem[] | null,
  isHidePrice = true,
): ILineItem[] => {
  if (!producLineItems || producLineItems.length === 0) {
    return []
  }
  return producLineItems.map(item => ({
    quantity: item.quantity,
    unit_price: item.unit_price,
    type: item.type?.label || '',
    type_id: item.type?.value || null,
    description: item.product?.label || '',
    product_id: item.product?.value,
    sku: item.sku || null,
    is_tax_exempted: Boolean(item.is_tax_exempted),
    is_hidden: Boolean(item.is_hidden) ? 1 : 0,
    is_optional: Boolean(item.is_optional),
    sub_description: item?.sub_description || '',
  }))
}
export const mapLineItemToForm = (
  lineItems: ILineItem[] | null,
): IFormLineItem[] => {
  if (!lineItems || lineItems.length === 0) {
    return []
  }
  return lineItems.map((i, idx) => ({
    id: idx + 1,
    product: !!i.description
      ? { value: i?.product_id || null, label: i.description }
      : null,
    sub_description: i?.sub_description || '',
    quantity: i.quantity,
    unit_price: i.unit_price,
    sku: i.sku,
    is_tax_exempted: Boolean(i.is_tax_exempted),
    is_hidden: i?.is_hidden || 0,
    type: i.type
      ? {
          value: i.type_id,
          label: i.type,
        }
      : null,
    is_optional: Boolean(i.is_optional),
  }))
}

export const checkHasHidenPriceLineItem = (lineItems: ILineItem[] | null) => {
  if (!lineItems || lineItems.length === 0) {
    return false
  }
  return lineItems.every(item => item.is_hidden === 1)
}

export const countTotalLineItem = (subTotal: number, tax = 0, discount = 0) => {
  return subTotal + tax - +discount
}

export const totalSaleTax = (
  taxVendors: OptionValueTax[] | ITaxVendor[] | null,
) => {
  if (!taxVendors) {
    return 0
  }
  return [...taxVendors].reduce((total, item) => {
    return total + ((item?.sales_tax ? +item?.sales_tax : 0) + 0)
  }, 0)
}

export const countSaleTax = (
  products: Array<IFormLineItem | ILineItem> | null,
  taxVendor = 0,
  discount = 0,
) => {
  if (!products || products.length === 0) {
    return 0
  }
  const totalAmountTax = products.reduce((total, item) => {
    const tax = item.is_tax_exempted ? 0 : taxVendor
    const price = (item.quantity || 0) * (item.unit_price || 0)
    return tax > 0 ? total + price : total
  }, 0)
  return ((totalAmountTax - discount) * taxVendor) / 100
}

export const mapListConditionsDefault = (settings: IDetailSettings[]) => {
  return settings.reduce((total: OptionValue[], item: IDetailSettings) => {
    return item.default === '1'
      ? [...total, { label: item.label, value: item.id }]
      : []
  }, [])
}
export const mapWarrantyTermDefault = (settings: IDetailSettings[]) => {
  const result = settings.find(item => item.default === '1')
  if (result) {
    return {
      value: result.id,
      label: result.label,
    }
  }
  return null
}

export const getFullNameContact = (contact: IContact) => {
  return [contact?.first_name, contact?.middle_name, contact?.last_name]
    .filter(Boolean)
    .join(' ')
}

export const totalSpecificSaleTax = (taxVendors: ITaxVendor[] | null) => {
  if (!taxVendors) {
    return 0
  }
  return [...taxVendors].reduce((total, item) => {
    return total + ((item?.sales_tax ? +item?.sales_tax : 0) + 0)
  }, 0)
}

export const handleTaxData = (taxVendors: OptionValueTax[]) => {
  const mergedData = Array.from(
    taxVendors
      .reduce((map, item) => {
        const key = `${item.value}-${item.label}`

        if (!map.has(key)) {
          map.set(key, {
            ...item,
            sales_tax: parseFloat(item.sales_tax ?? ''),
          })
        } else {
          map.get(key).sales_tax += parseFloat(item.sales_tax ?? '')
        }

        return map
      }, new Map())
      .values(),
  )
  return mergedData
}
