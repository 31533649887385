import cx from 'classnames'
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js'
import parsePhone, {
  formatIncompletePhoneNumber as formatPhoneLib,
} from 'libphonenumber-js/min'
import { ChangeEvent, InputHTMLAttributes, useEffect, useState } from 'react'

import countryNames from '../assets/countries.json'
import { Input } from '../components'
import { ErrorCircle } from '../icons'
import {
  DEFAULT_CALLING_CODE,
  DEFAULT_COUNTRY_CODE,
  formatPhone,
  INT_CODE,
} from '../utils/phoneNumber'

type Props = {
  phoneNumber?: string
  onChange?: (v: string, c: string) => void
  error?: boolean
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>

// export type Props = {
//   size?: 'small' | 'default' | 'large'
//   block?: boolean
//   prefixIcon?: ReactNode
//   suffix?: ReactNode
//   extra?: ReactNode
//   error?: boolean
//   extraClass?: string
// } &
export const PhoneNumberField = ({
  phoneNumber = '+1',
  onChange,
  error = false,
}: Props) => {
  const [inValidPhone, setInValid] = useState(!error)
  const [phone, setPhone] = useState<string>('')
  const [country, setCountry] = useState('')
  const [callingCode, setCallingCode] = useState('')

  const handleChangeCountry = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    let newPhone = ''
    setCountry(e.target.value as CountryCode)
    if (value !== INT_CODE) {
      const code = getCountryCallingCode(value as CountryCode)
      setCallingCode('+' + code)
      newPhone = '+' + code
    }
    setPhone(newPhone)
    onSubmitPhone(newPhone)
  }

  const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const prefix =
      phone.at(0) !== '+' ? (country !== INT_CODE ? callingCode : '+') : ''
    const resultFormat = formatPhoneLib(prefix + value, country as CountryCode)
    const resultParse = parsePhone(resultFormat)
    setPhone(resultFormat)
    onSubmitPhone(resultFormat)
    if (resultParse?.country && country === INT_CODE) {
      setCountry(resultParse.country)
    }
    if (inValidPhone) {
      setInValid(false)
    }
  }

  const initState = () => {
    const isHasPhone = phoneNumber !== DEFAULT_CALLING_CODE
    const initPhone = isHasPhone ? formatPhoneLib(phoneNumber) : phoneNumber
    const initCountry = (
      isHasPhone
        ? parsePhone(phoneNumber)?.country || INT_CODE
        : DEFAULT_COUNTRY_CODE
    ) as CountryCode
    const initCallingCode =
      isHasPhone && initCountry.toString() !== INT_CODE
        ? '+' + getCountryCallingCode(initCountry)
        : initCountry.toString() === INT_CODE
          ? ''
          : DEFAULT_CALLING_CODE
    return {
      phone: initPhone,
      country: initCountry,
      callingCode: initCallingCode,
    }
  }

  const onSubmitPhone = (p: string) => {
    onChange?.(p.replace(/\s/g, ''), country)
  }

  useEffect(() => {
    const {
      phone: initPhone,
      country: initCountry,
      callingCode: initCallingCode,
    } = initState()
    setPhone(initPhone)
    setCountry(initCountry)
    setCallingCode(initCallingCode)
  }, [])

  useEffect(() => {
    const { phone: initPhone } = initState()
    setPhone(initPhone)
  }, [phoneNumber])

  useEffect(() => {
    setInValid(error)
  }, [error])

  return (
    <div
      className={cx(
        'relative border border-separation-900 rounded-lg text-body flex items-center',
        error && 'border-red-900',
      )}
    >
      <div className='inline-flex gap-2 items-center p-3 bg-separation-200 cursor-pointer rounded-l-lg border-r border-r-separation-900 h-10'>
        <span
          className={cx(
            'rounded-lg w-4 h-4 bg-cover',
            country === INT_CODE
              ? 'font-icon-global h-auto w-auto'
              : `fi fi-${country.toLowerCase()}`,
          )}
        />
        <span className='text-body leading-none'>
          {country === INT_CODE ? 'Int' : country}
        </span>
        <span className='font-icon-arrow_down' />
        <select
          onChange={handleChangeCountry}
          value={country}
          className='absolute left-3 bg-transparent opacity-0 w-[4.375rem] cursor-pointer'
        >
          {countryNames.map(item => (
            <option key={item.code} value={item.code}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
      <div className='h-10 rounded-r-lg flex-1'>
        <Input
          value={phone}
          onChange={handleChangePhone}
          className='border-none focus-visible:border-none focus-visible:outline-0 pr-7'
        />
      </div>
      {error && (
        <span className='absolute top-1/2 translate-y-[-50%] right-2'>
          <ErrorCircle className='text-red-900' />
        </span>
      )}
    </div>
  )
}

export const PhoneNumberView = ({
  phone,
  showFlag = true,
  phoneClassName = '',
  wrapperClassName = '',
}: {
  phone?: string
  phoneClassName?: string
  wrapperClassName?: string
  showFlag?: boolean
}) => {
  if (!phone) {
    return null
  }

  const country = parsePhone(phone)
  if (!country) {
    return (
      <a
        href={`tel:${phone}`}
        target='_blank'
        className={cx(
          'underline text-black-800',
          wrapperClassName,
          phoneClassName,
        )}
      >
        {phone}
      </a>
    )
  }

  return (
    <a
      className={cx('inline-flex gap-2 items-center', wrapperClassName)}
      href={`tel:${phone}`}
      target='_blank'
    >
      {country.country && showFlag && (
        <span
          className={cx(
            `fi fi-${country.country?.toLowerCase()}`,
            'rounded-lg w-4 h-4 bg-cover',
          )}
        />
      )}
      <div className={cx('underline', phoneClassName)}>
        {formatPhone(phone)}
      </div>
    </a>
  )
}
